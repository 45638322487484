var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Autocomplete',_vm._g(_vm._b({attrs:{"label":_vm.designatedExpertLabel,"rules":_vm.rules.required,"item-text":"name","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('order.dispose.noExpertsFound'))}})],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
          ((_vm.$t('order.dispose.expertDistance')) + ": " + (_vm.getExpertDistance(
            item
          )) + " km")
        )}}),(item.expertUser)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(((_vm.$t('order.dispose.expertUser')) + ": " + (item.expertUser)))}}):_vm._e(),(item.location)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(((_vm.$t('order.dispose.expertLocation')) + ": " + (item.location)))}}):_vm._e(),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
          ((_vm.$t('order.dispose.expertAddress')) + ": " + (_vm.getExpertAddress(item)))
        )}})],1)]}}])},'Autocomplete',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }