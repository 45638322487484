import { AxiosInstance } from 'axios'
import ApiService from './base'
import TokenService from './token'
import axios from 'axios'

export interface DocumentServiceConfig {
  baseUrl?: string,
}

export default class DocumentService {
  protected appId: number
  protected instance: AxiosInstance

  constructor(opts: DocumentServiceConfig) {
    const token = TokenService.getToken()
    this.appId = 20 // TODO load from env

    const baseUrl = opts?.baseUrl || `${process.env.VUE_APP_DOCUMENT_SERVICE}/api`
    this.instance = axios.create({
      baseURL: baseUrl,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        common: {
          Authorization: token ? `Bearer ${token}` : null
        }
      }
    })
  }

  query(resource: string, params?: any): Promise<any> {
    return this.instance.get(resource, params)
  }

  fetchFiles(orderId: number, params?: any): Promise<any> {
    return this.instance.get(`list/documents/${this.appId}/${orderId}`, params)
  }

  fetchFileTypes(): Promise<any> {
    return this.instance.get(`list/utypes`)
  }

  delete(file: any) {
    return this.instance.get(file.delete)
  }

  update(file: { order_id: number, id: number }, typeId: number) {
    return this.instance.post(`update`, {
      order_id: file.order_id,
      document_id: file.id,
      id_type: typeId
    })
  }

  upload(orderId: number, file: { file: File, type: number }) {
    let formData = new FormData()
    formData.append('app_id', '300') // TODO app id richten
    formData.append('order_id', orderId.toString())
    formData.append('type_id', file.type.toString())
    formData.append('blob[]', file.file, file.file.name)
    return this.instance.post(`upload`, formData)
  }
}
