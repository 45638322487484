<template>
  <OrderPage
    :title="$t('order.history.title')"
    :subtitle="$t('order.history.subtitle')"
    @refresh="refresh"
    :loading="loading"
    :orderStatus="orderStatus"
    :saveBtn="false"
  >
    <HistoryTimeline v-if="history.length > 0" :history="history" />
    <span v-else v-text="$t('order.history.noActivity')" />
  </OrderPage>
</template>

<script>
import HistoryService from '@/services/history'
import OrderPageMixin from '@/mixins/order-page'
import HistoryTimeline from '@/components/Order/HistoryTimeline'

export default {
  name: 'order-page-history',
  mixins: [OrderPageMixin],
  components: { HistoryTimeline },
  data: () => ({
    history: [],
  }),
  created() {
    this.fetchOrder()
    this.fetchHistory()
  },
  methods: {
    refresh() {
      this.fetchHistory()
        .then(() => this.showInfo(this.$t('message.pageRefreshed')))
        .catch(() => this.showError(this.$t('message.pageRefreshFailed')))
    },
    async fetchHistory() {
      if (this.orderId) {
        this.loading = true
        await HistoryService.getOrderHistory(this.orderId)
          .finally(() => this.loading = false)
          .then(response => this.history = response.data)
      }
    },
  },
}
</script>
