export interface ExpertConfig {
  id?: number
  name?: string
  city?: string
  street?: string
  zipcode?: string
  distance?: number
  location?: string
  organisation?: string
  organisationId?: number
}

export default class Expert {
  public id: number = 0
  public name: string = ''
  public city: string = ''
  public street: string = ''
  public zipcode: string = ''
  public distance: number = 0
  public location: string = ''
  public organisation: string = ''
  public organisationId: number = 0

  constructor(opts: ExpertConfig) {
    Object.assign(this, opts);
  }
}