<template>
  <OrderPage
    :title="$t('order.comments.title')"
    :subtitle="$t('order.comments.subtitle')"
    :saveBtn="false"
    :loading="commentsLoading"
    :orderStatus="orderStatus"
    @refresh="refresh"
  >
    <template #actions>
      <TooltipButton
        icon
        @click.stop="createDialog = true"
        :tooltip="$t('order.comments.createCommentBtn')"
        ><v-icon color="success">mdi-plus-thick</v-icon></TooltipButton
      >
    </template>

    <v-slide-y-transition>
      <v-list subheader three-line v-if="comments.length > 0">
        <template v-for="(comment, idx) in comments">
          <Comment
            :key="`order-${orderId}-comment-${comment.id}`"
            :comment="comment"
          />
          <v-divider v-if="idx < comments.length - 1" :key="`divider-${idx}`" />
        </template>
      </v-list>
      <span v-else v-text="$t('order.comments.noComments')" />
    </v-slide-y-transition>

    <CreateCommentDialog
      v-model="createDialog"
      @refresh="fetchComments"
      @close="createDialog = false"
    />
  </OrderPage>
</template>

<script>
import CreateCommentDialog from '@/components/Dialogs/CreateComment'
import CommentService from '@/services/comment'
import OrderPageMixin from '@/mixins/order-page'
import Comment from './Comment'

export default {
  name: 'order-page-comments',
  mixins: [OrderPageMixin],
  components: {
    Comment,
    CreateCommentDialog
  },
  data: () => ({
    commentsLoading: false,
    createDialog: false,
    comments: []
  }),
  mounted() {
    if (this.orderId) {
      this.fetchOrder()
      this.fetchComments()
    }
  },
  methods: {
    refresh() {
      this.fetchComments()
        .then(() => this.showInfo(this.$t('message.pageRefreshed')))
        .catch(() => this.showError(this.$t('message.pageRefreshFailed')))
    },
    async fetchComments() {
      this.commentsLoading = true
      return CommentService.query(this.orderId)
        .finally(() => this.commentsLoading = false)
        .then(comments => this.comments = comments)
    }
  }
}
</script>
