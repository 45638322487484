<template>
  <Table
    :headers="header"
    :items="files"
    :search="search"
    :loading="loading"
    :options.sync="options"
  >
    <template v-slot:item.name="{ item }">
      {{ item.name }}
    </template>
    <template v-slot:item.order_date="{ item }">
      {{ formatDateStr(item.order_date) }}
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ formatDateTimeStr24(item.created_at) }}
    </template>
    <template v-slot:item.type_id="{ item }">
      <Autocomplete
        :items="fileTypesArr"
        item-value="id"
        item-text="text"
        :label="fileTypeLabel"
        v-model="item.type_id"
        @change="onChangeType(item, $event)"
        single-line
        hide-details
        solo
        dense
        flat
      />
    </template>
    <template v-slot:item.mime="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            v-if="mimeIcon(item.mime)"
            v-text="mimeIcon(item.mime)"
          />
          <span v-else v-text="item.mime" />
        </template>
        {{ item.mime }}
      </v-tooltip>
    </template>

    <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <a :href="item.link + '/direct'" target="_blank">
            <v-icon color="success" class="mr-2" v-on="on"
              >mdi-file-download-outline</v-icon
            >
          </a>
        </template>
        <span v-text="$t('table.files.downloadFileTooltip')" />
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            color="error"
            class="mr-2"
            @click="deleteFile(item)"
            v-on="on"
            v-if="canDeleteDocument"
            >mdi-file-remove-outline</v-icon
          >
        </template>
        <span v-text="$t('table.files.deleteFileTooltip')" />
      </v-tooltip>
    </template>
  </Table>
</template>

<script>
import Mainbus from "@/mainbus";
import { mapGetters } from "vuex";
import TableMixin from "@/mixins/table";
import DocumentService from "@/services/documents";

export default {
  name: "table-files",
  mixins: [TableMixin],
  props: {
    errorFilter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expanded: [],
    // stores timeout handler
    cl: null,
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    files: [],
    fileTypes: {},
    fileTypesArr: [],
    service: null,
  }),
  destroyed() {
    if (this.cl) {
      clearTimeout(this.cl);
    }
  },
  async created() {
    // TODO auslagern
    this.service = new DocumentService();
  },
  methods: {
    onChangeType(file, typeId) {
      if (confirm(this.$t("order.documents.confirmChangeFileType"))) {
        this.service
          .update(file, typeId)
          .then(() =>
            this.showSuccess(this.$t("message.changeFileTypeSuccess"))
          )
          .then(() => this.fetchFiles())
          .catch(() => this.showError(this.$t("message.changeFileTypeFail")));
      }
    },
    mimeIcon(mime) {
      switch (mime) {
        case "image/jpeg":
          return "mdi-file-image";
        default:
          return "mdi-file-hidden";
      }
    },
    deleteFile(file) {
      if (confirm(this.$t("order.documents.confirmDeleteFile"))) {
        this.service
          .delete(file)
          .then(() => this.showSuccess(this.$t("message.deleteFileSuccess")))
          .then(() => this.fetchFiles());
      }
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    async fetchFileTypes() {
      await this.service
        .fetchFileTypes()
        .then((response) => (this.fileTypes = response.data))
        .then(
          () =>
            (this.fileTypesArr = Object.entries(this.fileTypes).map((t) => ({
              id: parseInt(t[0]),
              text: this.$t(`form.upload.type.${t[1]}`),
            })))
        );
    },
    async fetchFiles() {
      this.loading = true;
      await this.service
        .fetchFiles(this.orderId, {})
        .finally(() => (this.loading = false))
        .then((response) => {
          this.files = response.data;
          this.numTotal = response.data.length;
        })
        .catch((error) => console.log(error));
    },
  },
  async mounted() {
    if (this.orderId) {
      await this.fetchFileTypes();
      this.fetchFiles();
    }
  },
  computed: {
    ...mapGetters(["canDeleteDocument"]),
    orderId() {
      return this.$route.params.orderId;
    },
    fileTypeLabel() {
      return this.$t("table.files.fileTypeLabel");
    },
    header() {
      return [
        {
          text: this.$t("table.files.mime"),
          align: "center",
          sortable: true,
          value: "mime",
        },
        {
          text: this.$t("table.files.name"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("table.files.type"),
          align: "center",
          sortable: false,
          value: "type_id",
        },
        {
          text: this.$t("table.files.uploadedOn"),
          align: "center",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("table.actions"),
          align: "center",
          sortable: false,
          value: "action",
        },
      ];
    },
  },
  watch: {
    $route() {
      if (this.orderId) {
        this.fetchFiles();
      }
    },
  },
};
</script>
