import ApiService from './base'
import Comment from '@/models/comment'

export default {
  query(orderId, params?: any): Promise<Array<Comment>> {
    return ApiService.query(`/api/orders/${orderId}/comments`, {
      params: params
    })
      .then(response => response.data.map(c => new Comment(c)))
  },
  getComment(orderId, commentId): Promise<Comment> {
    return ApiService.query(`/api/orders/${orderId}/comments/${commentId}`)
      .then(response => new Comment(response.data))
  },
  createComment(orderId: number, params: { internal: boolean, comment: string }) {
    return ApiService.post(`/api/orders/${orderId}/comments`, params)
  }
}