var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{staticClass:"orders-table",attrs:{"headers":_vm.header,"items":_vm.orders,"search":_vm.searchStr,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.numTotal},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openOrder},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('TextField',{attrs:{"prepend-inner-icon":"mdi-magnify","clearable":"","label":_vm.inboxSearchLabel,"solo":"","hide-details":"","flat":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('TooltipButton',{attrs:{"icon":"","plain":"","color":"secondary","tooltip":_vm.$t('order.page.refreshTooltip')},on:{"click":_vm.refresh}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'orange darken-2' : 'orange'}},[_vm._v("mdi-refresh")])]}}])})],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.orderLocked(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red darken-1"},domProps:{"textContent":_vm._s('mdi-shield-key')}},'v-icon',attrs,false),on)):(_vm.orderAlreadyOpen(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green darken-1"},domProps:{"textContent":_vm._s('mdi-shield-key')}},'v-icon',attrs,false),on)):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green darken-1"},domProps:{"textContent":_vm._s('mdi-circle')}},'v-icon',attrs,false),on))]}}],null,true)},[(_vm.orderLocked(item))?_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.orders.lockedTooltip', { user: item.lockUsername }))}}):(_vm.orderAlreadyOpen(item))?_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.orders.alreadyOpenTooltip'))}}):_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.orders.notLockedTooltip'))}})])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTimeStr24(item.createdAt))+" ")]}},{key:"item.indicator",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.vehicle.indicator || '-')}})]}},{key:"item.maker",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.vehicle.maker || '-')}})]}},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.vehicle.model || '-')}})]}},{key:"item.vin",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.vehicle.vin || '-')}})]}},{key:"item.contractnr",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.contractnr || '-')}})]}},{key:"item.productDisplayName",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.order.productDisplayName || '-')}})]}},{key:"item.inspectioncity",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.order.inspectionCity || '-')}})]}},{key:"item.inspectionname",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.order.inspectionName || '-')}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.orderLocked(item))?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"brown darken-3"},on:{"click":function($event){return _vm.openOrder(item)}}},on),[_vm._v("mdi-folder-open")]):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.orders.openOrderTooltip'))}})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }