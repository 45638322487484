<template>
  <v-form ref="form" :errors="errors" v-model="valid">
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" md="6">
              <TextField v-model="order.maker" :label="manufacturerLabel"
            /></v-col>

            <v-col cols="12" md="6">
              <TextField v-model="order.model" :label="modelLabel"
            /></v-col>

            <v-col cols="12" md="6">
              <TextField v-model="order.indicator" :label="licensePlateLabel"
            /></v-col>

            <v-col cols="12" md="6">
              <VinScannerField v-model="order.vin" :label="vinLabel" />
            </v-col>

            <v-col cols="12" md="3">
              <TextField v-model="order.hsn" :label="hsnLabel" counter="4"
            /></v-col>

            <v-col cols="12" md="3">
              <TextField v-model="order.tsn" :label="tsnLabel" counter="5"
            /></v-col>

            <v-col cols="12" md="6">
              <TextField v-model="order.mileage" :label="mileageLabel"
            /></v-col>

            <v-col cols="12" md="6">
              <DatePickerField
                :initial="order.registrationDate"
                v-model="order.registrationDate"
                :label="registrationDateLabel"
            /></v-col>

            <v-col cols="12" md="6">
              <DatePickerField
                :initial="order.damageDate"
                v-model="order.damageDate"
                :label="damageDateLabel"
            /></v-col>

            <v-col cols="12" md="6">
              <TextArea v-model="order.damageText" :label="damageAreaLabel"
            /></v-col>

            <v-col cols="12" md="6">
              <TextArea v-model="order.oldDamageText" :label="oldDamageLabel"
            /></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'
import AddressCard from '@/components/Order/Address'
import AddressService from '@/services/address'
import VinScannerField from '@/components/Ui/VinScannerField'

export default {
  name: 'form-order-vehicle-data',
  mixins: [FormMixin],
  components: { AddressCard, VinScannerField },
  data: () => ({
    order: {},
  }),
  methods: {
    changeAddress(type, data) {
      AddressService.update(this.orderId, data)
        .then(() => this.showSuccess(this.$t('message.updateAddressSuccess')))
    }
  },
  mounted() {
    this.order = this.initial ? { ...this.initial } : this.order
  },
  watch: {
    initial(value) {
      this.order = value ? { ...value } : this.order
    }
  },
  computed: {
    manufacturerLabel() {
      return this.$t('form.order.manufacturerLabel')
    },
    modelLabel() {
      return this.$t('form.order.modelLabel')
    },
    licensePlateLabel() {
      return this.$t('form.order.licensePlateLabel')
    },
    vinLabel() {
      return this.$t('form.order.vinLabel')
    },
    hsnLabel() {
      return this.$t('form.order.hsnLabel')
    },
    tsnLabel() {
      return this.$t('form.order.tsnLabel')
    },
    mileageLabel() {
      return this.$t('form.order.mileageLabel')
    },
    registrationDateLabel() {
      return this.$t('form.order.registrationDateLabel')
    },
    damageDateLabel() {
      return this.$t('form.order.damageDateLabel')
    },
    damageAreaLabel() {
      return this.$t('form.order.damageAreaLabel')
    },
    oldDamageLabel() {
      return this.$t('form.order.oldDamageLabel')
    },
  }
}
</script>