<template>
  <v-form
    ref="form"
    @submit.prevent="onSubmit"
    :errors="errors"
    v-model="valid"
  >
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row dense>
        <v-col cols="12" md="8">
          <FileInput
            dense
            :label="uploadFieldLabel"
            v-model="upload.file"
            :rules="rules.required"
            @change="onFileInput"
          />
        </v-col>
        <v-col cols="12" md="4">
          <Autocomplete
            dense
            v-model="upload.type"
            item-value="id"
            :items="fileTypes"
            :label="fileTypeLabel"
            :rules="rules.required"
            :loading="loadingFileTypes"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-spacer />
          <Button
            :loading="uploadInProgress"
            :disabled="!upload.file"
            @click="onSubmit"
            color="primary"
            block
          >
            <v-icon left v-text="'mdi-upload'" />
            {{ $t("form.upload.uploadFileButton") }}
          </Button>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'
import DocumentService from '@/services/documents'

export default {
  name: 'form-upload',
  mixins: [FormMixin],
  props: {
    uploadInProgress: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    valid: false,

    upload: {
      file: null,
      type: null
    },

    service: null,
    fileTypes: [],
    loadingFileTypes: false,
  }),
  async created() {
    this.service = new DocumentService()
    this.fetchFileTypes()
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.upload)
      }
    },
    onFileInput(file) {
      this.upload.file = file
    },
    async fetchFileTypes() {
      this.loadingFileTypes = true
      await this.service.fetchFileTypes()
        .finally(() => this.loadingFileTypes = false)
        .then(response => this.fileTypes = Object.entries(response.data)
          .map(t => ({ id: t[0], text: this.$t(`form.upload.type.${t[1]}`) })))
    },
  },
  computed: {
    uploadFieldLabel() {
      return this.$t('form.upload.uploadFieldLabel')
    },
    fileTypeLabel() {
      return this.$t('form.upload.fileTypeLabel')
    }
  }
}
</script>
</template>