<template>
  <v-form
    ref="form"
    @submit.prevent="onSubmit"
    :errors="errors"
    v-model="valid"
  >
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row dense>
        <v-col cols="12" md="6">
          <ExpertSelector
            :loading="loadingExperts"
            :items="experts"
            v-model="item.expert"
            :rules="rules.required"
        /></v-col>
        <v-col cols="12" md="6">
          <DatePickerField
            :initial="item.terminatedDatetime"
            :label="designatedDateLabel"
            v-model="item.terminatedDatetime"
            :rules="rules.required"
          />
        </v-col>

        <v-col cols="12" md="6" v-if="expertAddress">
          <AddressCard :address="expertAddress" :title="expertLocationLabel" />
        </v-col>
        <v-col cols="12" md="6" v-if="inspectionAddress">
          <AddressCard
            :address="inspectionAddress"
            :title="inspectionLocationLabel"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'
import ExpertService from '@/services/expert'
import ExpertSelector from './ExpertSelector'
import AddressCard from '@/components/Order/Address'

export default {
  name: 'form-order-dispose',
  mixins: [FormMixin],
  components: { ExpertSelector, AddressCard },
  data: () => ({
    item: {
      expert: null,
      terminatedDatetime: '',
    },
    order: {
      id: null
    },

    experts: [],
    loadingExperts: false,
  }),
  async mounted() {
    this.order = this.initial ? { ...this.initial } : this.order
    this.item.terminatedDatetime = this.order?.order?.terminatedDatetime
  },
  watch: {
    initial(value) {
      this.order = value ? { ...value } : this.order
      this.item.terminatedDatetime = this.order?.order?.terminatedDatetime
      this.item.expert = this.experts.find(e => e.id === this.order.expertId)
    },
    'order.id'() {
      this.fetchExperts()
    }
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.comment)
      }
    },
    fetchExperts() {
      this.loadingExperts = true
      ExpertService.query({ orderId: this.order.id, active: 1 })
        .finally(() => this.loadingExperts = false)
        .then(experts => this.experts = experts)
        .then(() => this.experts = this.experts.sort((x, y) => x.distance < y.distance ? -1 : 1))
        .then(() => this.item.expert = this.experts.find(e => e.id === this.order.expertId))
    }
  },
  computed: {
    expertAddress() {
      return this.order.order?.expertAddress
    },
    inspectionAddress() {
      return this.order.order?.inspectionAddress
    },
    inspectionAddressName() {
      return this.order.order?.inspectionAddress?.name || '-'
    },
    designatedAddressName() {
      return this.order.order?.expertAddress?.name || '-'
    },
    expertLocationLabel() {
      return this.$t('form.dispose.designatedLocationLabel')
    },
    designatedDateLabel() {
      return this.$t('form.dispose.designatedDateLabel')
    },
    inspectionLocationLabel() {
      return this.$t('form.dispose.inspectionLocationLabel')
    },
  },
}
</script>
