<template>
  <OrderPage
    :title="$t('order.dispose.title')"
    :subtitle="$t('order.dispose.subtitle')"
    :loading="loading"
    :orderStatus="orderStatus"
    @refresh="refresh"
    @save="save"
  >
    <template #actions>
      <TooltipButton
        icon
        plain
        v-if="orderStatus < 300"
        @click="revokeOrder"
        :tooltip="$t('order.dispose.revokeOrderTooltip')"
      >
        <v-icon v-text="`mdi-alert-remove`" color="error" />
      </TooltipButton>
    </template>
    <DisposeForm ref="disposeForm" :initial="order" :errors="errors" />
    <v-card v-if="history.length > 0" outlined tile>
      <v-card-title v-text="$t('order.dispose.disposeHistoryTitle')" />
      <v-card-text>
        <HistoryTimeline :history="history" />
      </v-card-text>
    </v-card>
  </OrderPage>
</template>

<script>
import OrderPageMixin from '@/mixins/order-page'
import DisposeForm from '@/components/Forms/Dispose'
import OrderService from '@/services/order'
import HistoryTimeline from '@/components/Order/HistoryTimeline'
import HistoryService from '@/services/history'

export default {
  name: 'order-page-dispose',
  mixins: [OrderPageMixin],
  components: { DisposeForm, HistoryTimeline },
  data: () => ({
    errors: null,
    history: []
  }),
  created() {
    if (this.orderId) {
      this.fetchOrder()
      this.fetchDisposeHistory()
    }
  },
  methods: {
    refresh() {
      this.fetchOrder()
        .then(() => this.fetchDisposeHistory())
        .then(() => this.showInfo(this.$t('message.pageRefreshed')))
        .catch(() => this.showError(this.$t('message.pageRefreshFailed')))
    },
    async fetchDisposeHistory() {
      this.loading = true
      await HistoryService.query(this.orderId, { type: 'dispose' })
        .finally(() => this.loading = false)
        .then(response => this.history = response.data)
    },
    save() {
      if (this.$refs.disposeForm.validate()) {
        const order = this.$refs.disposeForm.item
        order.expertId = order.expert.id || undefined

        if (order.expert.organisationId) {
          order.organisationId = order.expert.organisationId
        }

        OrderService.disposeOrder(this.orderId, order)
          .then(() => this.showSuccess(this.$t('message.disposeOrderSuccess')))
          .then(() => this.refresh())
          .then(() => this.errors = null)
          .catch(error => this.errors = error.response.data)
          .catch(() => this.showError(this.$t('message.disposeOrderFail')))
      }
    },
    revokeOrder() {
      if (confirm(this.$t('order.dispose.revokeOrderConfirm'))) {
        OrderService.revokeOrder(this.orderId)
          .then(() => this.refresh())
          .then(() => this.showSuccess(this.$t('message.revokeOrderSuccess')))
          .catch(() => this.showError(this.$t('message.revokeOrderFail')))
      }
    },
  },
}
</script>
