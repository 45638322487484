import OrderService from '@/services/order'

export default {
  data: () => ({
    order: null,
    loading: false,
  }),
  methods: {
    async fetchOrder() {
      if (this.orderId) {
        this.loading = true
        return OrderService.getOrder(this.orderId)
          .finally(() => this.loading = false)
          .then(response => this.order = response.data)
          // on error, go to 404
          .catch(error => this.$router.push({ name: '404' }))
      }
    }
  },
  computed: {
    orderId() {
      // @ts-ignore
      return this.$route.params.orderId
    },
    inboxId() {
      // @ts-ignore
      return this.$route.params.inboxId
    },
    orderStatus() {
      return this.order?.orderStatus || 0
    }
  }
}
