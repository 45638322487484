<template>
  <v-form ref="form" :errors="errors" v-model="valid">
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row dense>
        <v-col cols="12" md="6">
          <TextField
            :value="terminatedDate"
            :label="designatedDateLabel"
            disabled
          />
        </v-col>

        <v-col cols="12" md="6">
          <TextField
            :value="desiredDatetime"
            :label="desiredDateLabel"
            disabled
        /></v-col>

        <v-col cols="12" md="6">
          <TextField v-model="order.byOrderOf" :label="byOrderOfLabel" disabled
        /></v-col>

        <v-col cols="12" md="6">
          <TextField v-model="order.orderGroup" :label="customerGroupLabel"
        /></v-col>

        <v-col cols="12" md="6">
          <TextField
            v-model="order.responsibleUser"
            :label="responsiblePersonLabel"
            disabled
        /></v-col>

        <v-col cols="12" md="6">
          <TextField v-model="order.product" :label="productNameLabel" disabled
        /></v-col>

        <v-col cols="12" md="6">
          <TextField
            v-model="order.contractNumber"
            :label="contractNumberLabel"
        /></v-col>

        <v-col cols="12" md="6">
          <TextField
            v-model="order.inventoryNumber"
            :label="inventoryNumberLabel"
        /></v-col>

        <v-col cols="12" md="6">
          <TextField
            :value="$t(order.lawyer)"
            :label="lawyerAssignedLabel"
            disabled
        /></v-col>

        <v-col cols="12" md="6">
          <TextField v-model="order.damageNumber" :label="damageNumberLabel"
        /></v-col>

        <v-col cols="12" md="6">
          <TextField
            v-model="order.referenceNumber"
            :label="referenceNumberLabel"
        /></v-col>

        <v-col cols="12" md="6">
          <TextField :value="arrivalDate" :label="arrivalDateLabel" disabled
        /></v-col>

        <v-col cols="12">
          <TextArea v-model="order.infoText" :label="orderInfoLabel"
        /></v-col>

        <v-col cols="12" xl="6">
          <AddressCard
            addressType="inspection"
            v-if="order.inspectionAddress"
            :customer="customerId"
            :address="order.inspectionAddress"
            :title="$t('order.data.inspectionLocation')"
            @update="changeAddress('inspection', $event)"
            editable
          />
        </v-col>

        <v-col cols="12" xl="6">
          <AddressCard
            addressType="expert"
            v-if="order.expertAddress"
            :customer="customerId"
            :address="order.expertAddress"
            :title="$t('order.data.expertLocation')"
            @update="changeAddress('expert', $event)"
            editable
          />
        </v-col>

        <v-col cols="12" xl="6">
          <AddressCard
            addressType="invoice"
            v-if="order.billAddress"
            :customer="customerId"
            :address="order.billAddress"
            :title="$t('order.data.billingAddress')"
            @update="changeAddress('billing', $event)"
            editable
          />
        </v-col>

        <v-col cols="12" xl="6">
          <AddressCard
            addressType="report"
            v-if="order.reportAddress"
            :customer="customerId"
            :address="order.reportAddress"
            :title="$t('order.data.reportAddress')"
            @update="changeAddress('report', $event)"
            editable
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'
import AddressCard from '@/components/Order/Address'

export default {
  name: 'form-order-data',
  mixins: [FormMixin],
  components: { AddressCard },
  props: {
    customerId: {
      type: Number
    }
  },
  data: () => ({
    order: {},
  }),
  methods: {
    changeAddress(type, data) {
      switch (type) {
        case 'inspection':
          this.order.inspectionAddress = data
          break;
        case 'billing':
          this.order.billAddress = data
          break;
        case 'expert':
          this.order.expertAddress = data
          break;
        case 'report':
          this.order.reportAddress = data
          break;
      }
    }
  },
  mounted() {
    this.order = this.initial ? { ...this.initial } : this.order
  },
  computed: {
    terminatedDate() {
      return this.order.terminatedDatetime
        ? this.formatDateStr(this.order.terminatedDatetime) : ''
    },
    arrivalDate() {
      return this.order.arrivalDate
        ? this.formatDateStr(this.order.arrivalDate) : ''
    },
    desiredDatetime() {
      return this.order.desiredDatetime
        ? this.formatDateStr(this.order.desiredDatetime) : ''
    },
    designatedDateLabel() {
      return this.$t('form.order.designatedDateLabel')
    },
    desiredDateLabel() {
      return this.$t('form.order.desiredDateLabel')
    },
    byOrderOfLabel() {
      return this.$t('form.order.byOrderOfLabel')
    },
    customerGroupLabel() {
      return this.$t('form.order.customerGroupLabel')
    },
    responsiblePersonLabel() {
      return this.$t('form.order.responsiblePersonLabel')
    },
    productNameLabel() {
      return this.$t('form.order.productNameLabel')
    },
    contractNumberLabel() {
      return this.$t('form.order.contractNumberLabel')
    },
    inventoryNumberLabel() {
      return this.$t('form.order.inventoryNumberLabel')
    },
    lawyerAssignedLabel() {
      return this.$t('form.order.lawyerAssignedLabel')
    },
    damageNumberLabel() {
      return this.$t('form.order.damageNumberLabel')
    },
    referenceNumberLabel() {
      return this.$t('form.order.referenceNumberLabel')
    },
    arrivalDateLabel() {
      return this.$t('form.order.arrivalDateLabel')
    },
    orderInfoLabel() {
      return this.$t('form.order.orderInfoLabel')
    },
  },
  watch: {
    initial(value) {
      this.order = value ? { ...value } : this.order
    }
  },
}
</script>