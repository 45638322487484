<template>
  <v-list-item>
    <template v-slot:default="{ active }">
      <v-list-item-content>
        <v-list-item-title>
          <span v-if="comment.user" v-text="comment.user.name || '-'" />
          <span v-if="comment.internal">
            ({{ $t("order.comments.internalComment") }})</span
          >
        </v-list-item-title>

        <v-list-item-subtitle
          class="text--primary"
          v-text="comment.content"
        ></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-list-item-action-text v-if="comment.date" class="align-baseline">
          <v-icon left small>mdi-clock</v-icon>
          <span class="mt-1" v-text="formatDateTime(comment.date)" />
        </v-list-item-action-text>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
export default {
  name: 'order-comments-comment',
  props: {
    comment: {
      type: Object,
      required: true
    }
  }
}
</script>