<template>
  <OrderPage
    :title="$t('order.data.title')"
    :subtitle="$t('order.data.subtitle')"
    :saving="saving"
    :loading="loading"
    :orderStatus="orderStatus"
    @refresh="refresh"
    @save="save"
  >
    <template #extra>
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary" />
        <v-tab>
          <v-icon v-text="'mdi-file-document'" left />
          {{ $t("order.data.title") }}
        </v-tab>

        <v-tab>
          <v-icon v-text="'mdi-car'" left />
          {{ $t("order.vehicleData.title") }}
        </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="tab">
      <v-tab-item eager>
        <v-card flat>
          <OrderDataForm
            v-if="order"
            ref="orderForm"
            :initial="order.order"
            :customerId="order.customerId"
          />
        </v-card>
      </v-tab-item>
      <v-tab-item eager>
        <v-card flat>
          <VehicleDataForm
            v-if="order"
            ref="vehicleDataForm"
            :initial="order.vehicle"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </OrderPage>
</template>

<script>
import OrderPageMixin from '@/mixins/order-page'
import OrderService from '@/services/order'

import OrderDataForm from '@/components/Forms/OrderData'
import VehicleDataForm from '@/components/Forms/VehicleData'

export default {
  name: 'order-page-data-index',
  components: { OrderDataForm, VehicleDataForm },
  mixins: [OrderPageMixin],
  data: () => ({
    tab: 0,
    saving: false,
  }),
  async created() {
    await this.fetchOrder()
  },
  methods: {
    refresh() {
      this.fetchOrder()
        .then(() => this.showInfo(this.$t('message.pageRefreshed')))
        .catch(() => this.showError(this.$t('message.pageRefreshFailed')))
    },
    save() {
      if (this.$refs.orderForm.validate() &&
        this.$refs.vehicleDataForm.validate()) {
        const data = {
          order: this.$refs.orderForm.order,
          vehicle: this.$refs.vehicleDataForm.order
        }

        this.saving = true
        OrderService.update(this.orderId, data)
          .finally(() => this.saving = false)
          .then(() => this.showSuccess(this.$t('message.orderUpdateSuccess')))
          .catch((error) => console.log(error))
          .catch(() => this.showError(this.$t('message.orderDataSaveFailed')))
      }
    },
  },
  computed: {
    tabs() {
      return [
        {
          name: this.$t('order.data.title'),
          component: OrderDataForm
        },
        {
          name: this.$t('order.vehicleData.title'),
          component: VehicleDataForm
        }
      ]
    }
  }
}
</script>
