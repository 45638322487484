import Expert from '@/models/expert'
import ApiService from './base'

export default {
  query(params?: any): Promise<Array<Expert>> {
    return ApiService.query(`/api/experts`, {
      params: params
    })
      .then(response => response.data.map(expert => new Expert(expert)))
  },
  getExpert(expertId: number): Promise<Expert> {
    return ApiService.query(`/api/experts/${expertId}`)
      .then(response => new Expert(response.data))
  },
}