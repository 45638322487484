<template>
  <v-dialog max-width="800" v-bind="$attrs" v-on="$listeners">
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title v-text="$t('order.comments.create.title')" />
        <v-card-text>
          <CommentForm ref="commentForm" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <Button
            @click="save"
            color="success"
            v-text="$t('dialog.comment.createCommentLabel')"
          />
          <Button
            text
            @click="dialog.value = false"
            v-text="$t('dialog.closeDialogLabel')"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import CommentForm from '@/components/Forms/Comment'
import CommentService from '@/services/comment'

export default {
  name: 'order-create-comment',
  components: {
    CommentForm
  },
  methods: {
    save() {
      if (this.$refs.commentForm.validate()) {
        const comment = this.$refs.commentForm.comment
        CommentService.createComment(this.orderId, comment)
          .then(() => this.showSuccess(this.$t('message.createCommentSuccess')))
          .then(() => this.$emit('refresh'))
          .then(() => this.$emit('close'))
      }
    },
  },
  computed: {
    orderId() {
      return this.$route.params.orderId
    }
  }
}
</script>