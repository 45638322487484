<template>
  <Table
    :headers="header"
    :items="orders"
    :search="searchStr"
    :loading="loading"
    :options.sync="options"
    :server-items-length="numTotal"
    @click:row="openOrder"
    class="orders-table"
  >
    <template v-slot:top>
      <v-toolbar flat dense>
        <TextField
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="search"
          :label="inboxSearchLabel"
          solo
          hide-details
          flat
          outlined
          dense
        />
        <v-spacer />
        <TooltipButton
          icon
          plain
          color="secondary"
          @click="refresh"
          :tooltip="$t('order.page.refreshTooltip')"
        >
          <v-hover v-slot="{ hover }">
            <v-icon :color="hover ? 'orange darken-2' : 'orange'"
              >mdi-refresh</v-icon
            >
          </v-hover>
        </TooltipButton>
      </v-toolbar>
    </template>
    <template v-slot:item.status="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-if="orderLocked(item)"
            v-text="'mdi-shield-key'"
            color="red darken-1"
            v-bind="attrs"
            v-on="on"
          />
          <v-icon
            v-else-if="orderAlreadyOpen(item)"
            v-text="'mdi-shield-key'"
            color="green darken-1"
            v-bind="attrs"
            v-on="on"
          />
          <v-icon
            v-else
            v-text="'mdi-circle'"
            color="green darken-1"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <span
          v-if="orderLocked(item)"
          v-text="$t('table.orders.lockedTooltip', { user: item.lockUsername })"
        />
        <span
          v-else-if="orderAlreadyOpen(item)"
          v-text="$t('table.orders.alreadyOpenTooltip')"
        />
        <span v-else v-text="$t('table.orders.notLockedTooltip')" />
      </v-tooltip>
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ formatDateTimeStr24(item.createdAt) }}
    </template>
    <template v-slot:item.indicator="{ item }">
      <span v-text="item.vehicle.indicator || '-'" />
    </template>
    <template v-slot:item.maker="{ item }">
      <span v-text="item.vehicle.maker || '-'" />
    </template>
    <template v-slot:item.model="{ item }">
      <span v-text="item.vehicle.model || '-'" />
    </template>
    <template v-slot:item.vin="{ item }">
      <span v-text="item.vehicle.vin || '-'" />
    </template>
    <template v-slot:item.contractnr="{ item }">
      <span v-text="item.contractnr || '-'" />
    </template>
    <template v-slot:item.productDisplayName="{ item }">
      <span v-text="item.order.productDisplayName || '-'" />
    </template>
    <template v-slot:item.inspectioncity="{ item }">
      <span v-text="item.order.inspectionCity || '-'" />
    </template>
    <template v-slot:item.inspectionname="{ item }">
      <span v-text="item.order.inspectionName || '-'" />
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            color="brown darken-3"
            class="mr-2"
            @click="openOrder(item)"
            v-if="!orderLocked(item)"
            v-on="on"
            >mdi-folder-open</v-icon
          >
        </template>
        <span v-text="$t('table.orders.openOrderTooltip')" />
      </v-tooltip>
    </template>
  </Table>
</template>

<script>
import { mapGetters } from 'vuex'
import { differenceInMinutes } from 'date-fns'
import Mainbus from '@/mainbus'
import TableMixin from '@/mixins/table'
import OrderService from '@/services/order'

export default {
  name: 'table-orders',
  mixins: [TableMixin],
  props: {
    openOrders: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    searchStr: '',
    // stores timeout handler
    cl: null,
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    orders: [],
  }),
  destroyed() {
    if (this.cl) {
      clearTimeout(this.cl)
    }
  },
  methods: {
    refresh() {
      this.fetchOrders()
    },
    orderLocked(order) {
      const lockTill = this.parseDate(order.lockTill)
      if (!order.lockTill) {
        return false
      }

      const diff = differenceInMinutes(lockTill, new Date())
      return diff > 0 && this.currentUserId != order.lockUserId
    },
    orderAlreadyOpen(order) {
      return this.openOrders.find(e => e == order.id) || this.currentUserId == order.lockUserId
    },
    openOrder(order) {
      Mainbus.$emit('openOrder', order.id)
    },
    async fetchOrders() {
      this.loading = true
      await OrderService.query(this.tableParams)
        .finally(() => this.loading = false)
        .then(response => {
          this.orders = response.data.data
          this.numTotal = response.data.total
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    currentUserId() {
      return this.currentUser?.id
    },
    inboxSearchLabel() {
      return this.$t('table.orders.inboxSearchLabel')
    },
    inboxId() {
      return this.$route.params.inboxId
    },
    tableParams() {
      return {
        paginate: true,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        limit: this.options.itemsPerPage,
        sort: this.options.sortBy ? this.options.sortBy[0] : null,
        sortDesc: this.options.sortDesc ? this.options.sortDesc[0] : null,
        search: this.search,
        inbox: this.inboxId
      }
    },
    header() {
      var columnOptionFields = {
        0: 'id',
        1: 'productDisplayName',
        2: 'indicator',
        3: 'vin',
        4: 'maker',
        5: 'model',
        6: 'inspectioncity',
        7: 'inspectionname'
      }
      var columnOptionNames = {
        0: 'table.orders.orderId',
        1: 'table.orders.productName',
        2: 'table.orders.licensePlate',
        3: 'table.orders.vin',
        4: 'table.orders.maker',
        5: 'table.orders.model',
        6: 'table.orders.inspectionplace',
        7: 'table.orders.inspectionname'
      }
      var columns = [
        {
          text: '',//this.$t('table.orders.status'),
          align: 'center',
          sortable: false,
          value: 'status',
        },
        {
          text: this.$t('table.orders.orderId'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('table.orders.productName'),
          align: 'left',
          sortable: true,
          value: 'productDisplayName',
        },
        {
          text: this.$t('table.orders.licensePlate'),
          align: 'left',
          sortable: true,
          value: 'indicator',
        },
        {
          text: this.$t('table.orders.vin'),
          align: 'left',
          sortable: true,
          value: 'vin',
        },
        {
          text: this.$t('table.orders.creationDate'),
          align: 'left',
          sortable: false,
          value: 'createdAt',
        },
      ]
      if (this.currentUser.AppOptions != null && typeof this.currentUser.AppOptions.functions[0] !== 'undefined') {
        columns = [
          {
            text: '',//this.$t('table.orders.status'),
            align: 'center',
            sortable: false,
            value: 'status',
          },
        ]
        var columnOptions = this.currentUser.AppOptions.functions.map(createSort)
        columnOptions.sort();
        columnOptions.forEach((element) => {
          if (typeof element !== 'undefined') {
            columns.push({
              text: this.$t(columnOptionNames[this.currentUser.AppOptions.functions[element[1]].option_id]),
              align: 'left',
              sortable: true,
              value: columnOptionFields[this.currentUser.AppOptions.functions[element[1]].option_id],
            })
          }
        })
        console.log(columns);
        columns.push({
          text: this.$t('table.orders.creationDate'),
          align: 'left',
          sortable: false,
          value: 'createdAt',
        })
        function createSort(value, index) {
          if (value.active == 1) {
            return [value.custom1, index];
          }
        }
      }
      return columns
    },
  },
  watch: {
    '$route'() {
      this.fetchOrders()
    },
    tableParams: {
      async handler() {
        this.cl = setTimeout(this.fetchOrders, 400)
      },
      deep: true,
    },
  }
}
</script>

<style>
.orders-table tbody tr {
  cursor: pointer;
}
</style>
