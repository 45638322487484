<template>
  <OrderPage
    :title="$t('order.documents.title')"
    :subtitle="$t('order.documents.subtitle')"
    :saveBtn="false"
    @refresh="refresh"
    :orderStatus="orderStatus"
  >
    <UploadForm
      @submit="onUpload"
      ref="uploadForm"
      :uploadInProgress="uploading"
    />

    <v-divider />
    <Button
      :loading="downloading"
      :href=zipLink
      target="_blank"
      color="primary"
      block
    >
      <v-icon left v-text="'mdi-download'" />
      {{ $t("form.upload.downloadZipFileButton") }}
    </Button>
    <v-divider />
    <FileTable ref="table" />
  </OrderPage>
</template>

<script>
import FileTable from '@/components/Table/Files'
import DocumentService from '@/services/documents'
import OrderPageMixin from '@/mixins/order-page'
import UploadForm from '@/components/Forms/Upload'
import DownloadMixin from '@/mixins/file-download'

export default {
  name: 'order-page-documents',
  mixins: [OrderPageMixin, DownloadMixin],
  components: {
    UploadForm,
    FileTable
  },
  data: () => ({
    service: null,
    fileTypes: [],
    type: null,
    uploading: false,
    downloading: false
  }),
  created() {
    this.fetchOrder()
    this.service = new DocumentService()
  },
  methods: {
    refresh() {
      this.$refs.table.fetchFiles()
        .then(() => this.showInfo(this.$t('message.pageRefreshed')))
        .catch(() => this.showError(this.$t('message.pageRefreshFailed')))
    },
    async onUpload(file) {
      this.uploading = true
      this.service.upload(this.orderId, file)
        .finally(() => this.uploading = false)
        .then(() => this.showSuccess(this.$t('message.uploadFileSuccess')))
        .then(() => this.$refs.uploadForm.reset())
        .then(() => this.$refs.table.fetchFiles())
        .catch(error => console.log(error))
        .catch(() => this.showError(this.$t('message.uploadFileError')))
    }
  },
  computed: {
    zipLink() {
      return process.env.VUE_APP_BASE_URL + "/orders/" + parseInt(this.$route.params.orderId) + "/zipdownload"
    }
  },
}
</script>
