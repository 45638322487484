<template>
  <v-timeline-item small :color="color">
    <v-row class="pt-1">
      <v-col cols="3">
        <strong>{{ formatDateTimeStr24(item.updated_at) }}</strong>
        <div class="caption mb-2">
          {{ item.user.name }} {{ item.user.surname }}
        </div>
      </v-col>
      <v-col>
        <strong v-text="$t(`order.history.type.${item.type}`)" />
        <div
          class="caption mb-2"
          v-if="item.data.message"
          v-text="$t(item.data.message, item.data.parameters)"
        />
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
import HistoryColors from "@/configs/history-colors";

export default {
  name: "history-item",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      return HistoryColors[this.item.type]
        ? HistoryColors[this.item.type]
        : HistoryColors.default;
    },
  },
};
</script>
