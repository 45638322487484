<template>
  <Autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :label="designatedExpertLabel"
    :rules="rules.required"
    item-text="name"
    return-object
  >
    <template v-slot:selection="data">
      {{ data.item.name }}
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title v-text="$t('order.dispose.noExpertsFound')" />
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
        <v-list-item-subtitle
          v-text="
            `${$t('order.dispose.expertDistance')}: ${getExpertDistance(
              item
            )} km`
          "
        />
        <v-list-item-subtitle
          v-if="item.expertUser"
          v-text="`${$t('order.dispose.expertUser')}: ${item.expertUser}`"
        />
        <v-list-item-subtitle
          v-if="item.location"
          v-text="`${$t('order.dispose.expertLocation')}: ${item.location}`"
        />
        <v-list-item-subtitle
          v-text="
            `${$t('order.dispose.expertAddress')}: ${getExpertAddress(item)}`
          "
        />
      </v-list-item-content>
    </template>
  </Autocomplete>
</template>

<script>
import FormMixin from '@/mixins/form'

export default {
  name: 'dispose-expert-selector',
  mixins: [FormMixin],
  computed: {
    designatedExpertLabel() {
      return this.$t('form.dispose.designatedExpertLabel')
    },
  },
  methods: {
    getExpertDistance(item) {
      const formatter = new Intl.NumberFormat('de-DE', {  })
      return item.distance ? formatter.format(item.distance) : '-'
    },
    getExpertAddress(item) {
      return `${item.street || '-'}, ${item.zipcode || '-'} ${item.city || '-'}`
    }
  }
}
</script>
