export default {
  upload: 'blue darken-2',
  dispose: 'yellow lighten-1',
  revoke: 'red lighten-1',
  inspection: 'blue lighten-1',
  export: 'green lighten-3',
  finish: 'green lighten-1',
  cancel: 'red darken-1',
  create: 'blue darken-3',
  edit: 'blue darken-3',
  comment: 'blue darken-3',
  default: 'blue darken-3',
}