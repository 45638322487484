<template>
  <v-timeline align-top dense>
    <HistoryItem
      v-for="(item, idx) in history"
      :item="item"
      :key="`history-item-${idx}`"
    />
  </v-timeline>
</template>

<script>
import HistoryItem from './Item'

export default {
  name: 'order-history-timeline',
  components: { HistoryItem },
  props: {
    history: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>