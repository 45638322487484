import { parseISO } from 'date-fns'

interface User {
  id: number
  name: string
}

export interface CommentConfig {
  id?: number
  user?: User
  content?: string
  internal?: boolean
  updated_at?: string | Date
}

export default class Comment {
  public id: number = 0
  public user: User
  public content: string = ''
  public internal: boolean = false
  public date: Date | undefined

  constructor(opts: CommentConfig) {
    Object.assign(this, opts);

    this.user = opts.user || { id: 0, name: '' }

    if (opts.updated_at && typeof opts.updated_at == 'string') {
      this.date = parseISO(opts.updated_at)
    }
    else {
      this.date = new Date()
    }
  }
}