<template>
  <v-list dense>
    <template v-for="(item, idx) in items">
      <v-list-item :key="`field-${idx}`" v-if="item.show">
        <v-list-item-icon v-if="item.icon">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-text="item.icon" v-on="on" />
            </template>
            <span v-text="item.hint" />
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-title v-text="item.text" />
      </v-list-item> </template
  ></v-list>
</template>

<script>
export default {
  name: 'address-list',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>