<template>
  <ViewContainer>
    <v-card>
      <v-tabs
        v-if="inbox"
        v-model="activeTab"
        background-color="primary darken-2"
        dark
      >
        <v-tab exact :to="tableRoute">
          <v-icon left v-text="getIcon(inbox.id)" />
          {{ $t("page.inbox.inboxTitle", { name: $t(inbox.name) }) }}
        </v-tab>
        <v-tab
          v-for="orderId in orders"
          :key="`inbox-${inbox.name}-order-${orderId}`"
          :to="orderRoute(orderId)"
        >
          <span
            class="mr-2"
            v-text="$t('page.inbox.orderTabLabel', { orderId: orderId })"
          />

          <TooltipButton
            @click.prevent="closeOrder(orderId)"
            :tooltip="$t('order.page.closeTooltip')"
            icon
            plain
            small
          >
            <v-hover v-slot="{ hover }">
              <v-icon
                :color="hover ? 'error' : ''"
                v-text="'mdi-close-circle-outline'"
              />
            </v-hover>
          </TooltipButton>
        </v-tab>
      </v-tabs>
      <keep-alive max="5">
        <router-view :key="$route.fullPath" exclude="TableOrders"></router-view>
      </keep-alive>
    </v-card>
  </ViewContainer>
</template>

<script>
import Mainbus from '@/mainbus'
import InboxService from '@/services/inbox'
import OrderService from '@/services/order'
import InboxIcons from '@/configs/inbox-icons'

export default {
  data: () => ({
    orders: [],
    inbox: null,
    activeTab: '/',
    lockTimer: null,
    lockOrderInterval: 60000, // ms
  }),
  destroyed() {
    if (this.lockTimer) {
      clearInterval(this.lockTimer)
    }

    console.log("[INBOX] Closing inbox. Unlocking all orders")
    this.unlockOrders()
  },
  created() {
    Mainbus.$on('openOrder', this.openOrder)
    Mainbus.$on('closeOrder', this.closeOrder)

    this.fetchInbox()
    this.lockOrders()

    if (this.orderId) {
      this.lockOrder(this.orderId)
        .then(() => this.orders.push(this.orderId))
        .catch(() => this.showError(this.$t('message.orderLocked')))
    }

    this.lockTimer = setInterval(this.lockOrders, this.lockOrderInterval)
  },
  methods: {
    getIcon(icon) {
      return InboxIcons[icon]
    },
    lockOrders() {
      this.orders.forEach(order => this.lockOrder(order))
    },
    unlockOrders() {
      this.orders.forEach(order => this.unlockOrder(order))
    },
    lockOrder(orderId) {
      console.log("[INBOX] Locking order: ", orderId)
      return OrderService.lockOrder(orderId)
    },
    unlockOrder(orderId) {
      console.log("[INBOX] Unlocking order: ", orderId)
      return OrderService.unlockOrder(orderId)
    },
    openOrder(orderId) {
      if (!this.orders.includes(orderId)) {
        this.lockOrder(orderId)
          .then(() => this.orders.push(orderId))
          .then(() => this.$router.push(this.orderRoute(orderId)))
          .catch(() => this.showError(this.$t('message.orderLocked')))
      }
      else {
        this.showWarning(this.$t('message.orderAlreadyOpen'))
      }
    },
    fetchInbox() {
      // order was opened with global search
      if (this.inboxId == 0) {
        this.inbox = {
          id: 0,
          count: 0,
          name: 'inbox.search'
        }
        return
      }
      InboxService.getInbox(this.inboxId)
        .then(response => this.inbox = response.data)
    },
    getHref(route) {
      let props = this.$router.resolve(route)
      return props.href
    },
    orderRoute(orderId) {
      return {
        name: 'order.data',
        params: {
          orderId, inboxId: this.$route.params.inboxId
        }
      }
    },
    closeOrder(orderId) {
      this.$router.push(this.tableRoute)
      const idx = this.orders.indexOf(orderId)
      if (idx > -1) {
        this.orders.splice(idx, 1)
        this.unlockOrder(orderId)
      }
    }
  },
  computed: {
    inboxId() {
      return this.$route.params.inboxId
    },
    tableRoute() {
      return { name: 'inbox', params: { id: this.$route.params.inboxId } }
    },
    orderId() {
      return this.$route.params.orderId ? parseInt(this.$route.params.orderId) : null
    }
  },
  watch: {
    '$route': {
      deep: true,
      handler() {
        Mainbus.$emit('refreshInboxes')
        this.fetchInbox()
      }
    },
  },
}
</script>