import ApiService from './base'

export default {
  query(orderId, params?: any) {
    return ApiService.query(`/api/orders/${orderId}/history`, {
      params: params
    })
  },
  getOrderHistory(orderId) {
    return ApiService.query(`/api/orders/${orderId}/history`)
  },
}