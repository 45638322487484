<template>
  <v-form
    ref="form"
    @submit.prevent="onSubmit"
    :errors="errors"
    v-model="valid"
  >
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-switch :label="internalLabel" v-model="comment.internal" inset />
        </v-col>
        <v-col cols="12">
          <TextArea :label="commentLabel" v-model="comment.content" :rules="rules.required"
        /></v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'

export default {
  name: 'order-create-comment-form',
  mixins: [FormMixin],
  data: () => ({
    comment: {
      internal: false,
      content: ''
    },
  }),
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit('submit', this.comment)
      }
    },
  },
  computed: {
    internalLabel() {
      return this.$t('form.comment.internalLabel')
    },
    commentLabel() {
      return this.$t('form.comment.commentLabel')
    },
  }
}
</script>