<template>
  <v-card dense tile>
    <v-card-title class="pb-0">
      <v-icon left v-text="'mdi-card-account-details'" />
      <span v-text="title" v-if="title" />

      <v-spacer />

      <TooltipButton
        v-if="editable"
        small
        fab
        :tooltip="$t('order.address.editTooltip')"
        @click="addressDialog = true"
        color="primary"
      >
        <v-icon v-text="'mdi-pen'" />
      </TooltipButton>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <List :items="addressFields" />
        </v-col>
        <v-col>
          <List :items="contactFields" />
        </v-col>
      </v-row>
    </v-card-text>

    <AddressDialog
      :address="address"
      :customer="customer"
      :addressType="addressType"
      v-model="addressDialog"
      @update="update"
    />
  </v-card>
</template>

<script>
import AddressDialog from '@/components/Dialogs/Address'
import List from './List'

export default {
  name: 'order-address-card',
  components: { AddressDialog, List },
  props: {
    address: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false
    },
    addressType: {
      type: String,
    },
    customer: {
      type: Number,
    }
  },
  data: () => ({
    addressDialog: false,
  }),
  methods: {
    update(address) {
      this.$emit('update', address)
      this.addressDialog = false
    }
  },
  computed: {
    contactFields() {
      return [
        {
          hint: this.$t('form.address.mobileLabel'),
          icon: 'mdi-cellphone-sound',
          text: this.address.mobile || '-',
          show: true,
        },
        {
          hint: this.$t('form.address.phoneLabel'),
          icon: 'mdi-card-account-phone',
          text: this.address.phone || '-',
          show: true,
        },

        {
          hint: this.$t('form.address.mailLabel'),
          icon: 'mdi-card-account-mail',
          text: this.address.email || '-',
          show: true,
        },
        {
          hint: this.$t('form.address.faxLabel'),
          icon: 'mdi-fax',
          text: this.address.fax || '-',
          show: true,
        },
      ]
    },

    addressFields() {
      return [
        {
          hint: this.$t('order.address.company'),
          icon: 'mdi-factory',
          text: this.address.name,
          show: true,
        },
        {
          hint: this.$t('order.address.contactName'),
          icon: 'mdi-account',
          text: this.addressName,
          show: true,
        },
        {
          hint: this.$t('order.address.address'),
          icon: 'mdi-map-marker',
          text: this.addressStr,
          show: true,
        },
        {
          hint: this.$t('form.address.countryLabel'),
          icon: 'mdi-flag',
          text: this.address.country || '-',
          show: true,
        },
        {
          hint: this.$t('order.address.note'),
          icon: 'mdi-note-text-outline',
          text: this.address.note || '-',
          show: true,//this.address.note,
        },
      ]
    },
    addressName() {
      return `${this.address.title || ''} ${this.address.firstname || '-'} ${this.address.lastname || '-'}`
    },
    addressStr() {
      return `${this.address.street || '-'} ${this.address.streetNumber || '-'}, `
        + `${this.address.zipcode || '-'} ${this.address.city || '-'} ${this.address.state || ''}`
    }
  }
}
</script>